import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'

import WhatsAppWhite from '_assets/svgs/whatsAppWhite.svg'
import Svg from '_/components/svg'

import { IconButton } from '@mui/material'
import useRolePermission from '_/hooks/use-role-permission'
import useStyles from './styles'
import { HELP_NUMBER_STRING } from '_/utils/constants'
import { useSelector } from 'react-redux'
import {
  getGenericParametersLoadingSelector,
  getGenericParametersRequesterSelector,
} from '_/modules/service-orders/selectors'
import { openWhatsApp } from '_/utils/open-whatsApp'

export function HelpButton({ phoneNumber }) {
  const styles = useStyles()

  const { isIntermediary } = useRolePermission()
  const loading = useSelector(getGenericParametersLoadingSelector)
  const genericParameters = useSelector(getGenericParametersRequesterSelector)

  const showWhatsAppButton = genericParameters?.whatsappButton

  const movidesk = useCallback(() => {
    if (isIntermediary) {
      return (
        <Helmet>
          <script type="text/javascript">{`var mdChatClient="60795D6802C747BF88ACF6E5FB179443";`}</script>
          <script src="https://chat.movidesk.com/Scripts/chat-widget.min.js" />
        </Helmet>
      )
    }

    return (
      <Helmet>
        <script type="text/javascript">{`var mdChatClient="BFA509D28B814BD587B70AB4B57494EF";`}</script>
        <script src="https://chat.movidesk.com/Scripts/chat-widget.min.js" />
      </Helmet>
    )
  }, [isIntermediary])

  console.info('HelpButton', phoneNumber ?? HELP_NUMBER_STRING)

  // Render Components Items
  const helpIcon = useCallback(() => {
    if (showWhatsAppButton) {
      return (
        <>
          <IconButton
            className={styles.button}
            onClick={() => openWhatsApp(phoneNumber ?? HELP_NUMBER_STRING)}
          >
            <Svg icon={WhatsAppWhite} className={styles.svg} />
          </IconButton>
        </>
      )
    }

    return movidesk()
  }, [showWhatsAppButton, loading])

  if (loading || showWhatsAppButton === undefined) {
    return null
  }

  return helpIcon()
}
